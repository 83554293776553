<template>
  <div>
    <ElementSupplierModal
      :treeId="selectedTreeId"
      :addColumns="tableDataConfigSuppliers"
    />
    <BCard>
      <div class="pb-6">
        <el-button
          v-if="isTemplate"
          @click="handleShowFormDialog(null, true, true)"
          type="primary"
          >Thêm cấu trúc gốc
        </el-button>

        <el-button
          v-if="isTemplate"
          @click="
            dialogStructureTemplate = true;
            excel = null;
          "
          type="primary"
          >Import Excel
        </el-button>
      </div>
      <TreeTable
        :resizableColumns="false"
        class="text-sm overflow-auto"
        :value="trees"
        :paginator="true"
        :rows="10"
        :showGridlines="true"
        style="font-size: 14px"
        selectionKeys="selectedKeys1"
      >
        <Column
          header="Mã"
          :expander="true"
          headerClass="p-text-center"
          headerStyle="width: 600px"
        >
          <template #body="slotProps">
            <p class="" style="display: inline">
              <span style="color: rgb(54, 108, 243); font-weight: 600">{{
                slotProps.node.data.fileName
              }}</span>

              <span class="ml-2">{{ slotProps.node.data.name }}</span>
            </p>
          </template>
        </Column>
        <Column
          header="Diễn giải"
          field="description"
          headerStyle="width: 200px"
        >
        </Column>
        <Column header="Unit" field="unit" headerStyle="width: 200px">
          <template #body="slotProps">
            <p class="" style="display: inline">
              {{ getUnitString(slotProps.node.data.unit) }}
            </p>
          </template>
        </Column>
        <Column
          header="Nhân công"
          field="labourCost"
          headerStyle="width: 100px"
        >
          <template #body="slotProps">
            <p class="" style="display: inline">
              {{
                _.isNumber(slotProps.node.data.labourCost)
                  ? slotProps.node.data.labourCost
                  : ""
              }}
            </p>
          </template>
        </Column>

        <Column
          headerStyle="width: 80px"
          headerClass="p-text-center sm-invisible"
          bodyClass="p-text-center sm-invisible"
          header="Thao tác"
        >
          <template #body="slotProps">
            <el-popover placement="bottom" width="150" trigger="hover">
              <el-button slot="reference">Action</el-button>
              <div class="d-flex flex-column">
                <Button
                  type="button"
                  label="Supplier"
                  iconPos="left"
                  icon="pi pi-copy"
                  @click="handleSupplierFormDialog(slotProps.node.data.id)"
                  class="p-button-success mb-1"
                ></Button>

                <Button
                  type="button"
                  label="Copy"
                  iconPos="left"
                  icon="pi pi-copy"
                  @click="handleDuplicate(slotProps.node.data.id)"
                  class="p-button-success mb-1"
                ></Button>

                <Button
                  type="button"
                  label="Add"
                  iconPos="left"
                  icon="pi pi-plus"
                  class="p-button-success mb-1"
                  @click="handleShowFormDialog(slotProps.node.data, true)"
                ></Button>

                <Button
                  type="button"
                  label="Edit"
                  iconPos="left"
                  icon="pi pi-pencil"
                  @click="handleShowFormDialog(slotProps.node.data, false)"
                  class="p-button-warning mb-1"
                ></Button>

                <Button
                  label="Delete"
                  iconPos="left"
                  @click="handleDeleteTree(slotProps.node.data.id)"
                  icon="pi pi-times"
                  class="p-button-danger"
                />
              </div>
            </el-popover>
          </template>
        </Column>
      </TreeTable>
    </BCard>

    <el-dialog title="Cấu trúc" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" :rules="rules" label-width="120px" ref="ruleForm">
        <el-form-item label="Tên cấu trúc" prop="name">
          <el-input type="text" v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="Diễn giải" prop="description">
          <el-input type="text" v-model="form.description"></el-input>
        </el-form-item>

        <el-form-item label="Mã cấu trúc" prop="code">
          <el-input type="text" v-model="form.code"></el-input>
        </el-form-item>

        <el-form-item label="Đơn vị" prop="unit">
          <el-select v-model="form.unit" placeholder="Select Unit">
            <el-option label="m" :value="1"> </el-option>
            <el-option label="m2" :value="2"> </el-option>
            <el-option label="m3" :value="3"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Nhân Công" prop="labourCost">
          <el-input type="number" v-model="form.labourCost"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleAddUpdate()">Ok</el-button>
          <el-button @click="dialogVisible = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="Import Mẫu Cấu Trúc"
      :visible.sync="dialogStructureTemplate"
      width="30%"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item>
          <el-upload
            action=""
            ref="upload"
            :on-change="handleChangeFile"
            :list-type="'xlsx'"
            :limit="1"
            :auto-upload="false"
          >
            <el-button size="small" type="primary"
              >Chọn file excel (.xlsx)
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleImportExcel()">Ok</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton, BCard, BPopover, BLink } from "bootstrap-vue";
import Button from "primevue/button";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import Vue from "vue";
import ElementSupplierModal from "../tree-database/ElementSupplierModal.vue";

import VueContext from "vue-context";
Vue.directive("tooltip", Tooltip);
export default {
  components: {
    ElementSupplierModal,
    BButton,
    TreeTable,
    Column,
    BCard,
    Button,
    BPopover,
    VueContext,
    BLink,
  },
  data() {
    return {
      selectedTreeId: null,
      dialogStructureTemplate: false,
      file: null,
      selectedKeys3: [],
      predefineColors: [
        "#ff4500",
        "",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Bạn hãy nhập tên nhé",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Bạn hãy nhập mã nhé",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      trees: [],
      baseTemplate: {},
      form: {
        name: "",
        code: "",
        id: null,
        isTypical: false,
        color: "",
      },
      menuData: [
        { icon: "PlusIcon", text: "New" },
        { icon: "FileIcon", text: "Open" },
        { icon: "SaveIcon", text: "Save" },
        { icon: "SaveIcon", text: "Save As" },
        { icon: "XIcon", text: "Close" },
      ],
      tableDataConfigSuppliers: [],
    };
  },
  async created() {
    await this.getBaseTemplate();
    await this.getConfigSupplier();
  },
  methods: {
    async getConfigSupplier() {
      let res = await this.$store.dispatch(
        "configModule/actionGetConfigSupplier"
      );

      this.tableDataConfigSuppliers = res.data.data.data;

      if (!Array.isArray(this.tableDataConfigSuppliers)) {
        this.tableDataConfigSuppliers = [];
      }
    },
    handleSupplierFormDialog(id) {
      this.selectedTreeId = id;
      this.$bvModal.show("bv-modal-example");
    },
    getUnitString(number) {
      switch (number) {
        case 1:
          return "m";
        case 2:
          return "m2";
        case 3:
          return "m";
        default:
          return "";
      }
    },
    handleClick() {
      console.log("a", this.$refs["menu5a7a85b2-5db1-4ab1-8afd-74a29cc6ec61"]);
      this.$refs["menu5a7a85b2-5db1-4ab1-8afd-74a29cc6ec61"].open;
    },
    handleChangeFile(file, fileList) {
      this.excel = file;
    },
    async handleImportExcel() {
      if (!this.excel) {
        this.$notify({
          title: "Warning",
          message: "Bạn cần chọn excel file mẫu!",
          type: "warning",
        });
      }
      this.dialogStructureTemplate = false;
      await this.$store.dispatch(
        "templateModule/actionAddExcel",
        this.excel.raw
      );

      await this.getBaseTemplate();
    },
    async handleDuplicate(id) {
      await this.$store.dispatch("treeModule/actionDuplicateTree", id);
      await this.getBaseTemplate();
    },
    handleShowFormDialog(data, isAdd, isAddLevel0 = false) {
      this.dialogVisible = true;
      if (isAddLevel0) {
        this.isAdd = true;
        this.form.idParent = null;
        this.form.name = "";
        this.form.code = "";
        this.form.idHost = this.baseTemplate.id;
        return;
      }
      this.dialogVisible = true;
      this.isAdd = isAdd;
      if (isAdd) {
        this.form.idParent = data.id;
        this.form.name = "";
        this.form.code = "";
        this.form.idHost = data.idHost;
      } else {
        this.form = { ...data };
      }
    },
    async handleAddUpdate() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;
          console.log("this.form", this.form);
          if (this.isAdd) {
            await this.$store.dispatch("treeModule/actionAddTree", this.form);
          } else {
            await this.$store.dispatch(
              "treeModule/actionUpdateTree",
              this.form
            );
          }
          await this.getBaseTemplate();
        } else {
          return;
        }
      });
    },
    async handleDeleteTree(id) {
      let rs = await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });

      if (rs.value) {
        await this.$store.dispatch("treeModule/actionDeleteTree", id);

        await this.getBaseTemplate();
      }
    },
    async getBaseTemplate() {
      let res = await this.$store.dispatch("templateModule/actionGetTemplate");
      this.baseTemplate = res.data.data;
      this.trees = res.data.data.treeItemResponse;
    },
  },
  props: {
    isTemplate: false,
  },
  computed: {
    ...mapGetters({
      currentProject: "projectModule/getCurrentProject",
      currentProjectId: "projectModule/getCurrentProjectId",
    }),
  },
  watch: {
    async currentProjectId() {
      await this.getBaseTemplate();
    },
  },
};
</script>


