<template>
  <div>
    <b-modal
      id="bv-modal-example"
      hide-footer
      ref="my-modal"
      size="xl"
      content-class="modal1"
    >
      <div>
        <b-modal
          id="modal-import"
          title="Thêm nhà cung cấp từ File Excel"
          hide-footer
        >
          <div>
            <b-form-file
              v-model="file"
              ref="fileinput"
              accept="application/vnd.ms-excel"
              class="mb-2"
            ></b-form-file>
          </div>
          <b-row class="flex-row-reverse mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="importExcel()"
            >
              Ok
            </b-button>
          </b-row>
        </b-modal>
      </div>
      <template #modal-title>Suppliers</template>
      <div>
        <ValidationObserver ref="form">
          <b-form>
            <b-row>
              <b-col cols="4">
                <b-form-group label="Nhà cung cấp" label-for="supplier">
                  <ValidationProvider
                    name="nhaCungCap"
                    rules="required"
                    #default="{ errors }"
                  >
                    <b-form-input
                      id="supplier"
                      v-model="model.nhaCungCap"
                      placeholder="Nhà cung cấp"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Xuất xứ" label-for="xuat-xu">
                  <ValidationProvider
                    name="xuatXu"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="xuat-xu"
                      v-model="model.xuatXu"
                      placeholder="Xuất xứ"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Tiêu chuẩn" label-for="tieu-chuan">
                  <ValidationProvider
                    name="tieuChuan"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="tieu-chuan"
                      v-model="model.tieuChuan"
                      placeholder="Tiêu chuẩn"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group label="Nhãn hiệu" label-for="nhan-hieu">
                  <ValidationProvider
                    name="nhanHieu"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="nhan-hieu"
                      v-model="model.nhanHieu"
                      placeholder="Nhãn hiệu"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
               <b-col cols="4">
                <b-form-group label="Spec" label-for="spec">
                  <ValidationProvider
                    name="supplier"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="spec"
                      v-model="model.spec"
                      placeholder="Spec"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Price" label-for="price">
                  <ValidationProvider
                    name="price"
                    rules="required|priceNumber"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="price"
                      v-model="model.price"
                      placeholder="Price"
                    />
                    <span class="input-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
             
              <b-col cols="4" v-for="(item, index) in addColumns" :key="index">
                <b-form-group :label="item.key" :label-for="item.name">
                  <b-form-input
                    :placeholder="item.name"
                    v-model="model.metaData[item.key]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="flex-row-reverse mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="updateSupplier()"
              >
                Update
              </b-button>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="addSuppier()"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="exportExcel()"
              >
                Export
              </b-button>
              <input
                ref="fileUploadExcel"
                type="file"
                accept="application/vnd.ms-excel"
                hidden
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="openImportExcelModal()"
              >
                Import
              </b-button>
            </b-row>
          </b-form>
        </ValidationObserver>

        <div>
          <DataTable
            :value="tableDatas"
            :selection.sync="selectedSupplier"

            selectionMode="single"
            dataKey="id"
            :scrollable="true"
            responsiveLayout="scroll"
            :paginator="true"
            class="p-datatable-customers"
            :rows="10"
            :filters="filters"
          >
            <Column field="nhaCungCap" header="Nhà cung cấp" sortable> </Column>
            <Column field="xuatXu" header="Xuất xứ" sortable></Column>
            <Column field="nhanHieu" header="Nhãn hiệu" sortable></Column>
            <Column field="tieuChuan" header="Tiêu Chuẩn" sortable></Column>
            <Column field="spec" header="Spec" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column
              :header="item.name"
              v-for="(item, index) in addColumns"
              :key="index"
            >
              <template #body="slotProps">
                {{ slotProps.data.metaData[item.key] }}
              </template>
            </Column>
            <Column field="action" header="Action">
              <template #body="rowData">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon mr-1"
                  @click="hanleEdit(rowData)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  @click="handleDelete(rowData)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BTable,
  BIcon,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "@axios";
import InputText from "primevue/inputtext";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, double } from "vee-validate/dist/rules";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Override the default message.
extend("required", {
  ...required,
  message: "This field is required",
});
extend("double", {
  ...double,
  message: "This field must be a double",
});

extend("priceNumber", {
  validate: (value) => {
    var number = parseFloat(value);
    if (number == 0) {
      return true;
    }
    if (!number) {
      return false;
    }
    return number >= 0;
  },
  message: "Price invalid",
});

export default {
  components: {
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BTable,
    BIcon,
    ValidationProvider,
    ValidationObserver,
    DataTable,
    Column,
    InputText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filters: {},
      selectedSupplier: null,
      selectRowId: null,
      model: {
        nhaCungCap: "",
        xuatXu: "",
        tieuChuan: "",
        nhanHieu: "",
        price: "",
        spec: "",
        metaData: {},
      },
      tableDatas: [],
    };
  },
  methods: {
    async importExcel() {
      if (this.$refs.fileinput.files.length == 1) {
        try{
        var bodyFormData = new FormData();
        bodyFormData.append("file", this.$refs.fileinput.files[0]);
        console.log(this.$refs.fileinput.files[0]);
        let res = await axios({
          url: `ElementSupplier/ImportSupplier/${this.treeId}`,
          method: "POST",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.data.succeeded) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "success",
              text: res.data.message,
              variant: "success",
            },
          });
          this.$bvModal.hide('modal-import');
        await this.getData();
        }else{
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Import nhà cung cấp không thành công",
              variant: "danger",
            },
          });
        }
        }catch{
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Import nhà cung cấp không thành công",
              variant: "danger",
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Warning",
            text: "Bạn chưa chọn Excel File",
            variant: "warning",
          },
        });
      }
    },
    openImportExcelModal() {
      this.$bvModal.show("modal-import");
    },
    async exportExcel() {
      let res = await axios({
        url: `/ElementSupplier/ExportExcel/${this.treeId}`,
        method: "POST",
        headers: { Accept: "application/vnd.ms-excel" },
        responseType: "blob",
      });

      const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      try {
        let split1 = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[1];
        let downloadName = split1.split("*=UTF-8''")[1];
        downloadName = decodeURIComponent(downloadName);
        link.download = downloadName ? downloadName : "ListSupplier.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {}
    },
    async handleDelete(row) {
      var res = await axios.delete(`ElementSupplier/${row.data.id}`);
      if (res.data.succeeded) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Success",
            text: `Đã xoá thành công nhà cung cấp ${row.data.nhaCungCap}`,
            variant: "success",
          },
        });
      }
      await this.getData();
    },
    hanleEdit(row) {
      this.model = row.data;
      this.selectedSupplier = row.data.id;
    },
    async updateSupplier() {
      let isFormValid = await this.$refs.form.validate();
      if (isFormValid) {
        if (!this.model.id) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Warning",
              text: "Hãy chọn nhà cung cấp cần update",
              variant: "warning",
            },
          });
        } else {
          var res = await axios.put(
            `ElementSupplier/${this.model.id}`,
            this.model
          );
          if (res.data.succeeded) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: "Update nhà cung cấp thành công",
                variant: "success",
              },
            });
          }
        }
      }
    },
    async getData() {
      var res = await axios.get(`ElementSupplier/${this.treeId}`);
      this.tableDatas = res.data.data;
    },
    async addSuppier() {
      let isFormValid = await this.$refs.form.validate();
      if (isFormValid) {
        let payload = this.model;
        payload.idTree = this.treeId;
        try {
          let res = await axios.post("ElementSupplier", payload);
          if (res.data.succeeded) {
            await this.getData();
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: "Thêm nhà cung cấp thành công",
                variant: "success",
              },
            });
          }
        } catch {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Thêm nhà cung cấp không thành công",
              variant: "danger",
            },
          });
        }
      }
    },
  },
  props: {
    treeId: { type: String },
    addColumns: { type: Array },
  },
  watch: {
    treeId() {
      if (this.treeId) {
        this.getData();
      }
    },
  },
};
</script>
<style scoped>
.modal1 {
  width: 75% !important;
  margin: auto !important;
}
.actionClass {
  width: 150px;
}
.input-error {
  color: #f22435;
  display: block;
  margin-top: 5px;
  margin-left: 2px;
}
</style>